import React from "react";
import {_t} from "../../js/translation";

export default ({ eventUuid }) =>
    <div className="btn-group-column">
        <a href={`/ical_file/${eventUuid}/google`} target="_blank" style={{ "width": "100%" }} className="btn theme_styles--button">
            {_t('scheduling.add_to_google')}
        </a>
        <a href={`/ical_file/${eventUuid}/ical`} style={{ "width": "100%" }} className="btn theme_styles--button">
            {_t('scheduling.add_to_ical')}
        </a>
        <a href={`/ical_file/${eventUuid}/other`} style={{ "width": "100%" }} className="btn theme_styles--button">
            {_t('scheduling.add_to_other')}
        </a>
    </div>;
