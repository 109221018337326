export const EVENT_TYPE_URL = 'event_types';
export const EVENTS_URL = 'events';
export const SCHEDULING_URL = 'booking';
export const SCHEDULING_CREATE_EVENT_URL = 'booking/create';
export const SCHEDULING_RESCHEDULE_EVENT_URL = 'booking/reschedule';
export const SCHEDULING_CANCELLATION_EVENT_URL = 'booking/cancellation';
export const ORGANIZATION_MEMBERS_URL = uuid => `organizations/${uuid}/members`;
export const ORGANIZATION_INVITE_MEMBERS_URL = uuid => `organizations/${uuid}/invitations`;
export const ORGANIZATION_DELETE_MEMBER_URL = (organizationUuid, uuid) => `organizations/${organizationUuid}/members/${uuid}`;
export const ORGANIZATION_SHOW_MEMBER_URL = (organizationUuid, uuid) => `organizations/${organizationUuid}/members/${uuid}/show`;
export const ORGANIZATION_HIDE_MEMBER_URL = (organizationUuid, uuid) => `organizations/${organizationUuid}/members/${uuid}/hide`;
export const ORGANIZATION_EXPORT_MEMBERS_MEETINGS_STATISTICS_URL = (organizationUuid) => `organizations/${organizationUuid}/export/members-meetings-statistics`;
export const PAYMENT_STATUS_URL = 'payment/status';