import React from 'react';
import {isMilitaryTimeFormat} from '../../common/utils';
import {FormattedTime} from 'react-intl';
import moment from "moment/moment";
import _ from "lodash";
import {Visible} from "../../app/common/component/visible";
import classNames from "classnames";
import CalendarButtons from "./CalendarButtons";
import {Button, DropdownButton, Icon} from "../../app/common/ui-kit";
import {_t} from "../../js/translation";
import convertPHPDateFormat from "../../app/common/tools/convertPHPDateFormat";

class SelectedTimes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let _this = this;
        let formattedTimeProps = {
            timeZone: this.props.selectedTimezone
        };
        if (window.userTimeFormat !== 'auto') {
            formattedTimeProps.hourCycle = isMilitaryTimeFormat(window.userTimeFormat) ? 'h23' : 'h12';
        }

        return <React.Fragment>
            {_.map(_this.props.selectedTimes, (times, day) => (
                <div className={'multiple-meeting--block'} key={day}>
                    <h4 className={'multiple-meeting--block_title'}>{moment(day).format(convertPHPDateFormat(window.userDateFormat))}</h4>
                    <div className={'multiple-meeting--slots-list'}>
                        {times.map((time, index) => (
                            <div className={classNames({
                                'multiple-meeting--slots-list__item': true,
                                'multiple-meeting--slots-list__item--readonly': _this.props.readOnly && !(_this.props.isEventConfirmed && window.isInvitee && !window.isCalendarNotificationsEnabled)
                            })} key={index}>
                                <FormattedTime value={time} {...formattedTimeProps}/>
                                <Visible visible={!_this.props.readOnly}>
                                    <button className={'multiple-meeting--slots-list__item-remove'} onClick={() => _this.props.onSelectMultipleTime(time, day)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
                                    </button>
                                </Visible>

                                <Visible visible={_this.props.isEventConfirmed && window.isInvitee && !window.isCalendarNotificationsEnabled && !_.isEmpty(_this.props.selectedTimesWithUuid)}>
                                    {_this.props.selectedTimesWithUuid && _this.props.selectedTimesWithUuid.hasOwnProperty(time) ?
                                        <>
                                            <span className={'multiple-meeting--slots-list__item-phantom-space'}></span>
                                            <DropdownButton template={Button.TEMPLATE.ACTION} icon={Icon.ICON.CALENDAR_ADD} className={'slots-list__item-dropdown-toggler'}>
                                                <CalendarButtons eventUuid={_this.props.selectedTimesWithUuid[time]}/>
                                            </DropdownButton>
                                        </>
                                    : null}
                                </Visible>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </React.Fragment>;
    }
}

export default SelectedTimes;
