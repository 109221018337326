import React from "react";
import {isMilitaryTimeFormat, parseDate} from "../../common/utils";
import SelectedDaySidebar from "./SelectedDaySidebar";
import EventConfirmation from "./EventConfirmation";
import { FormattedDate, FormattedTime } from "react-intl";
import RescheduleForm from "./RescheduleForm";
import {_t} from "../../js/translation";
import SelectedTimes from "./SelectedTimes";
import _ from "lodash";
import {classNames} from "../../app/common/tools/react.dom.helpers";
import convertPHPDateFormat from "../../app/common/tools/convertPHPDateFormat";
import moment from "moment/moment";

class NewEventContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isRescheduleForm = this.props.isRescheduleForm;
    const eventTypeLocation = this.props.eventTypeLocation;
    const eventTypeLocationHidden = this.props.eventTypeLocationHidden;
    const useExternalLocation = this.props.useExternalLocation;
    const requestLocation = this.props.requestLocation;
    const isPaymentEnabled = this.props.isPaymentEnabled || window.isPaymentEnabled;
    const payment = this.props.payment || window.payment;
    const tax = this.props.tax || window.tax;
    const isPriceIncludesTax = this.props.isPriceIncludesTax || window.isPriceIncludesTax;
    let eventTypeDuration = this.props.eventTypeDuration || window.eventTypeDuration;
    const isPaymentStatusComponent = this.props.isPaymentStatusComponent || false;

    if (this.props.newEvent && this.props.newEvent.duration_id) {
      const eventTypeDurationsValue = window.eventTypeDurationsValue;
      if (eventTypeDurationsValue) {
        const eventTypeDurationValue = _.find(eventTypeDurationsValue, {value: this.props.newEvent.duration_id})
        if (eventTypeDurationValue) {
          eventTypeDuration = eventTypeDurationValue.label;
        }
      }
    }

    let formattedTimeProps = {
      timeZone: this.props.selectedTimezone
    };
    if (window.userTimeFormat !== 'auto') {
      // formattedTimeProps.hour12 = !isMilitaryTimeFormat(window.userTimeFormat);
      formattedTimeProps.hourCycle = isMilitaryTimeFormat(window.userTimeFormat) ? 'h23' : 'h12';
    }

    let isMultipleMeeting = window.isMultipleMeeting && !_.isEmpty(this.props.selectedTimes);
    let price = 0;
    let taxAmount = 0;
    let totalPrice = 0;
    let qty = isMultipleMeeting ? _.flatMap(this.props.selectedTimes, (arr) => arr).length : 1;

    if (isPaymentEnabled && payment !== null && payment !== payment.amount) {
      price = payment.amount;
      if (isPriceIncludesTax) {
        totalPrice = payment.amount * qty;
        if (tax) {
          taxAmount = totalPrice - (totalPrice / (1 + (tax.ratio / 100)));
        }
      } else {
        totalPrice = payment.amount * qty;
        if (tax) {
          totalPrice *= 1 + (tax.ratio / 100);
          taxAmount = totalPrice - payment.amount * qty;
        }
      }
    }

    return (
      <>
        <div
          className="event-block--step-item event-block--step-item__sidebar sidebar sidebar--selected-datails theme_styles--text theme_styles--line"
        >
          {!isMultipleMeeting ? <SelectedDaySidebar item={this.props.selectedDayDate}/> : null}

          <div className={classNames('scheduled', isMultipleMeeting && 'scheduled--with-multiple-slots' )}>

            <ul className="details-list v2">
              <li className={"details-list__item details-list__item--name"}>
                <span className="details-list__item-col details-list__item-col--title">{_t('Meeting name')}</span>
                <span className="details-list__item-col details-list__item-col--value">
                  <i title="Name" className="service-color" style={{ color: this.props.eventTypeColor, background: this.props.eventTypeColor }}></i>
                  {this.props.eventTypeName}
                </span>
              </li>
              {!isMultipleMeeting ?
                <li className={"details-list__item details-list__item--time"}>
                  <span className="details-list__item-col details-list__item-col--title">{_t('Time')}</span>
                  <span className="details-list__item-col details-list__item-col--value">
                    <FormattedTime value={parseDate(this.props.selectedTime)} {...formattedTimeProps}/> - <FormattedTime value={parseDate(this.props.selectedTime).add(eventTypeDuration, 'm')} {...formattedTimeProps}/>
                  </span>
                </li>
              : <li className={"details-list__item details-list__item--time"}>
                    <span className="details-list__item-col details-list__item-col--title">{_t('Duration')}</span>
                    <span className="details-list__item-col details-list__item-col--value">
                    {eventTypeDuration} {_t('min')}
                </span>
                </li>
              }
              {!isRescheduleForm ? null : (
              <li className={"details-list__item details-list__item--time-original"}>
                <span className="details-list__item-col details-list__item-col--title">{_t('Time original')}</span>
                <span className="details-list__item-col details-list__item-col--value">
                  {moment(this.props.eventOriginalSchedule).tz(this.props.selectedTimezone).format(convertPHPDateFormat(window.userDateFormat))}{" "}
                  <FormattedTime value={parseDate(this.props.eventOriginalSchedule)} {...formattedTimeProps}/>
                </span>
              </li>
              )}
              {isPaymentEnabled && payment !== null && payment !== payment.amount
                  ? <React.Fragment>

                    {!isPaymentStatusComponent || price === totalPrice && taxAmount === 0
                        ? <li className={"details-list__item details-list__item--price"}>
                          <span className="details-list__item-col details-list__item-col--title">{_t(price === totalPrice && taxAmount === 0 ? 'Total' : 'Price')}</span>
                          <span className="details-list__item-col details-list__item-col--value">
                              {price.toFixed(2)} {payment.currency} {qty > 1 ? ' x ' + qty : null}
                            </span>
                        </li>
                    : null }

                    {tax
                      ? <React.Fragment>
                        <li className={"details-list__item details-list__item--price"}>
                          <span className="details-list__item-col details-list__item-col--title">{tax.name}</span>
                          <span className="details-list__item-col details-list__item-col--value">
                          {taxAmount.toFixed(2)} {payment.currency}
                        </span>
                        </li>
                      </React.Fragment>
                    : null}

                    {price !== totalPrice || taxAmount !== 0
                        ? <li className={"details-list__item details-list__item--price"}>
                            <span className="details-list__item-col details-list__item-col--title">{_t('Total')}</span>
                            <span className="details-list__item-col details-list__item-col--value">
                          {totalPrice.toFixed(2)} {payment.currency}
                        </span>
                        </li>
                      : null}
                  </React.Fragment>
                  : null}
              <li className={"details-list__item details-list__item--timezone"}>
                <span className="details-list__item-col details-list__item-col--title">{_t('Timezone')}</span>
                <span className="details-list__item-col details-list__item-col--value">
                  {this.props.selectedTimezone}
                </span>
              </li>
              {useExternalLocation ? (
              <li className={"details-list__item details-list__item--location"}>
                <span className="details-list__item-col details-list__item-col--title">{_t('Location')}</span>
                <span className="details-list__item-col details-list__item-col--value">{_t('scheduling.conferencing_details')}</span>
              </li>
              ) : !requestLocation &&
              ((eventTypeLocation != "" &&
                  (this.props.isEventConfirmed && eventTypeLocationHidden)) ||
                  (eventTypeLocation != "" && !eventTypeLocationHidden)) ? (
              <li className={"details-list__item details-list__item--location"}>
                <span className="details-list__item-col details-list__item-col--title">{_t('Location')}</span>
                <span className="details-list__item-col details-list__item-col--value">
                  {eventTypeLocation}
                </span>
              </li>
              ) : null}
            </ul>

            {isMultipleMeeting ?
              <SelectedTimes
                  selectedTimes={this.props.selectedTimes}
                  selectedTimezone={this.props.selectedTimezone}
                  onSelectMultipleTime={this.props.onSelectMultipleTime}
                  isEventConfirmed={this.props.isEventConfirmed}
                  selectedTimesWithUuid={this.props.isEventConfirmed ? this.props.newEvent.multiple_scheduled_at_with_uuid : null}
                  readOnly={this.props.isEventConfirmed || isPaymentStatusComponent}
              />
            : null}

          </div>
        </div>

        {this.props.isEventConfirmed ? (
          <EventConfirmation
            isReschedule={isRescheduleForm}
            newEvent={this.props.newEvent}
            hostName={this.props.hostName}
          />
        ) : isRescheduleForm ? (
          <RescheduleForm
            onSubmit={this.props.onRescheduleSubmit}
            isLoading={this.props.isLoading}
          />
        ) : (
          this.props.children
        )}
      </>
    );
  }
}

export default NewEventContainer;
