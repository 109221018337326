import React from 'react';
import { parseDate } from '../../common/utils';
import {FormattedDate} from 'react-intl';
import {customFormattedDate} from "../../common/utilities/DateTimeUtilities";

class SelectedDaySidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const date = this.props.item;
        const parsedDate = parseDate(date);

        return (     
            <div className="date">
                <div className="date__left">
                    <span className="date__number">
                        <FormattedDate value={parsedDate} day='numeric'/>
                    </span>
                </div>
                <div className="date__right">
                    <div className="date__day">
                        {customFormattedDate(parsedDate, 'weekday', 'long')}
                    </div>
                    <div className="date__month">
                        {customFormattedDate(parsedDate, 'month', 'long')}
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectedDaySidebar;
