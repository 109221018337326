import React, { Component } from "react";
import CalendarButtons from "./CalendarButtons";
import {_t} from "../../js/translation";
import {Visible} from "../../app/common/component/visible";

class EventConfirmation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const userFullName = this.props.hostName || window.userFullName;
        const { isReschedule, newEvent } = this.props;

        return (
            <div className="event-block--step-item event-block--step-item__confirmed new-event--confirmed">
                <div className="confirmed-message">
                    <div className={'status status--success theme_styles--header_text'} style={{...{ display: "flex" }}}>
                        <i className={'icon'}>
                            <svg aria-hidden="true" data-prefix="fal" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-check fa-w-14 fa-2x"><path fill="currentColor" d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
                        </i>
                        {_t('scheduling.confirmed')}
                    </div>

                    <p className={'p theme_styles--text'}>
                        {isReschedule
                            ? _t('scheduling.reschedule.event_rescheduled')
                            : _t('scheduling.scheduled_with_%name%', {name: userFullName})
                        }
                    </p>
                    <Visible visible={!window.isMultipleMeeting}>
                        {window.isInvitee && !window.isCalendarNotificationsEnabled
                            ? <CalendarButtons eventUuid={newEvent.uuid}/>
                            : <p className={'p theme_styles--text'}>{_t('scheduling.scheduled_cal_notification_sent')}</p>
                        }
                    </Visible>
                </div>
            </div>
        );
    }
}

export default EventConfirmation;
