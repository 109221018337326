import {FormattedDate} from 'react-intl';
import React from "react";
import moment from "moment";

export function toYyyyMmDdString(dateObject) {
    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    if (day < 10) {
        day = `0${day}`;
    }

    if (month < 10) {
        month = `0${month}`;
    }

    return `${year}-${month}-${day}`;
}

export function toHhMmTimeString(dateObject) {
    let hour = dateObject.getHours();
    let minute = dateObject.getMinutes();

    if (hour < 10) {
        hour = `0${hour}`;
    }

    if (minute < 10) {
        minute = `0${minute}`;
    }

    return `${hour}:${minute}:00`;
}

export function convertDateToUTCDay(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function customFormattedDate(date, type, format) {
    let customFormat;

    switch (type) {
        case 'weekday':
            switch (format) {
                case 'long':
                    customFormat = 'dddd';
                    break;
                case 'short':
                    customFormat = 'ddd';
                    break;
            }
            break;
        case 'month':
            switch (format) {
                case 'long':
                    customFormat = 'MMMM';
                    break;
                case 'short':
                    customFormat = 'MMM';
                    break;
            }
            break;
    }

    let dateClone = moment(date);
    let langKey = type + '_' + format + '_' + dateClone.locale('en-gb').format(customFormat);

    if (typeof LANG !== 'undefined' && LANG && LANG[langKey]) {
        return LANG[langKey];
    }

    let options = {
        value: date
    }
    options[type] = format;

    return <FormattedDate
        {...options}
    />
}
