import React from "react";
import {PulseLoader} from 'halogenium';
import {_t} from "../../js/translation";

class RescheduleForm extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            reason: null,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();
        let eventUuid = window.eventUuid;
        this.props.onSubmit(eventUuid, this.state.reason);
    }

    onChangeHandler(field) {
        return (event) => {
            const reason = Object.assign({}, this.state.reason, {[field]: event.target.value});
            this.setState(reason);
        };
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div className="content theme_styles--text">
                <h3>{_t('scheduling.reschedule.reschedule_meeting')}</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>{_t('scheduling.reschedule.reason')}</label>
                        <textarea onChange={this.onChangeHandler("reason")} name="reason" className="form-control" placeholder={_t('scheduling.reschedule.reason_placeholder')}/>
                        {this.props.errors && this.props.errors.hasOwnProperty("errors") ?
                            <small
                                className="form-error">{this.props.errors.errors.children.invitee.children.fullName.errors}</small> : null}
                    </div>
                    <div className="form-group align-right">
                        <button type="submit" className={"btn btn--primary " + (!isLoading ? 'theme_styles--button' : '')} style={isLoading ? {backgroundColor: '#d9d9d9'} : {}}>
                            {isLoading ? _t('scheduling.wait') : _t('scheduling.reschedule.reschedule_meeting_button')}
                        </button>
                        {isLoading ? <PulseLoader color="#5bb9f4" size="16px" margin="4px"/> : null}
                    </div>
                </form>
            </div>
        );
    }
}

export default RescheduleForm;
